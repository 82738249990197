import {toast} from "react-toastify";

export function getConfiguration() {
	return {
		position: toast.POSITION.TOP_CENTER,
		autoClose: 4000,
		className: "bdo-styles",
	};
}

export function getAccountExpirationConfiguration() {
	return {
		position: toast.POSITION.TOP_CENTER,
		autoClose: 10000,
		className: "bdo-styles",
		closeButton: true,
	};
}

export function getPrintConfirmationConfiguration() {
	return {
		position: toast.POSITION.TOP_CENTER,
		autoClose: 10000,
		className: "bdo-styles",
		closeButton: true,
	};
}

export function getGenerateConfirmationConfiguration() {
	return {
		position: toast.POSITION.TOP_CENTER,
		autoClose: 10000,
		className: "bdo-styles",
		closeButton: true,
	};
}
