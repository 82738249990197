export const TYPE_FETCH_USERS = "TYPE_FETCH_USERS";
export const TYPE_FETCH_USERS_SUCCESS = "TYPE_FETCH_USERS_SUCCESS";
export const TYPE_FETCH_USERS_FAILURE = "TYPE_FETCH_USERS_FAILURE";

export const TYPE_FETCH_USER_LOGS = "TYPE_FETCH_USER_LOGS_USERS";
export const TYPE_FETCH_USER_LOGS_SUCCESS = "TYPE_FETCH_USER_LOGS_USERS_SUCCESS";
export const TYPE_FETCH_USER_LOGS_FAILURE = "TYPE_FETCH_USER_LOGS_USERS_FAILURE";

export const TYPE_EDIT_USER = "TYPE_EDIT_USER";
export const TYPE_EDIT_USER_SUCCESS = "TYPE_EDIT_USER_SUCCESS";
export const TYPE_EDIT_USER_FAILURE = "TYPE_EDIT_USER_FAILURE";

export const TYPE_DELETE_USER = "TYPE_DELETE_USER";
export const TYPE_DELETE_USER_SUCCESS = "TYPE_DELETE_USER_SUCCESS";
export const TYPE_DELETE_USER_FAILURE = "TYPE_DELETE_USER_FAILURE";

export const TYPE_EDIT_TOKENS = "TYPE_EDIT_TOKENS";
export const TYPE_EDIT_TOKENS_SUCCESS = "TYPE_EDIT_TOKENS_SUCCESS";
export const TYPE_EDIT_TOKENS_FAILURE = "TYPE_EDIT_TOKENS_FAILURE";

export const TYPE_SET_PASSWORD = "TYPE_SET_PASSWORD";
export const TYPE_SET_PASSWORD_SUCCESS = "TYPE_SET_PASSWORD_SUCCESS";
export const TYPE_SET_PASSWORD_FAILURE = "TTYPE_SET_PASSWORD_FAILURE";
