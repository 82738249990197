import {connect} from "react-redux";
import {UsersComponent} from "./users.component";
import {fetchUsers, deleteUser} from "../../store/actions";
import {getUsers} from "../../store/selectors/app.selectors";
import {updateFormDataAction} from "../../../../store/actions/updateFormData.actions";
import {getApiType, getFormData, getRefreshCurrentPageStatus} from "../../../../store/selectors/app.selectors";
import {clearRefreshCurrentPageAction} from "../../../../store/actions";

const mapStateToProps = (state) => {
	return {
		users: getUsers(state),
		exchangedData: getFormData(state),
		apiType: getApiType(state),
		refreshCurrentPageStatus: getRefreshCurrentPageStatus(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		fetchUsers: (apiType) => { dispatch(fetchUsers(apiType)); },
		updateFormData: (exchangedData) => { dispatch(updateFormDataAction(exchangedData)); },
		deleteUser: (userId) => { dispatch(deleteUser(userId)); },
		clearRefreshCurrentPage: () => { dispatch(clearRefreshCurrentPageAction()); },
	};
};

export const UsersRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(UsersComponent);
