import * as React from "react";
import {useEffect} from "react";
import {useStyles} from "./users.styles";
import {Container} from "../../../../components/container/container.component";
import {IUser} from "../../../../interfaces/user";
import {Header} from "../../../../components/header/header.component";
import {Button} from "../../../../components/button/button";
import {getFormattedDate, getDayDifference} from "../../../../helpers/datetime";
import classnames from "classnames";
import Paper from '@material-ui/core/Paper';
import EditIcon from "@material-ui/icons/Create";
import RemoveIcon from "@material-ui/icons/Delete";
import LogsIcon from "@material-ui/icons/Assessment";
import AddIcon from "@material-ui/icons/Add";
import {Locations as AdminLocations} from "../../constants/locations";
import {useHistory} from "react-router-dom";
import {DeleteUserDialog} from "../../containers/dialogs/deleteUser/deleteUserDialog";
import Tooltip from '@material-ui/core/Tooltip';
import ColorizeIcon from '@material-ui/icons/Colorize';
import {editTokens, getTokens} from "../../api/api";

export function UsersComponent(props) {
	const {
		users, fetchUsers, updateFormData, apiType, deleteUser, refreshCurrentPageStatus, clearRefreshCurrentPage
	} = props;
	const classes = useStyles({});
	const [state, setState] = React.useState({
		opened: false,
		user: null,
	});

	let history = useHistory();

	useEffect(() => {
		fetchUsers(apiType);
	}, []);

	useEffect(() => {
		if (refreshCurrentPageStatus !== null) {
			clearRefreshCurrentPage();
			fetchUsers(apiType);
		}
	}, [refreshCurrentPageStatus]);

	const handleEditClick = (userId) => {
		updateFormData({
			from: AdminLocations.USERS,
			userId: userId,
			formValues: users.find((user: IUser) => (user.id === userId)),
		});
		history.push(AdminLocations.EDIT_USER);
	};

	const handleViewLogsClick = (userId) => {
		updateFormData({
			from: AdminLocations.USERS,
			userId: userId,
			formValues: users.find((user: IUser) => (user.id === userId)),
		});
		history.push(AdminLocations.VIEW_USER_LOGS);
	};

	const handlePickUserClick = (userId) => {
		let defaultCarrerId;
		let defaultReceiverId;
		let defaultClientId;
		let defaultClientKey;
		const isDevelop = process.env.REACT_APP_MODE === "development";
		const isProduction = process.env.REACT_APP_MODE === "production";

		if (isDevelop) {
			defaultCarrerId = "5e5d5a924bbeb6004b335175";
			defaultReceiverId = "5e653f0b08e588004b4887e4";
			defaultClientId = "c52c1736-840d-4659-86f7-bb5d65503dc7";
			defaultClientKey = "647c695a4e9549338d6935f5130151743c32aaf9aee8416ea912a2972ec69cc8";
		} else if (isProduction) {
			defaultCarrerId = "5e528a8da4151e004bedc497";
			defaultReceiverId = "5e529686a4151e004bedc49e";
			defaultClientId = "";
			defaultClientKey = "";
		}

		const handleError = (data) => {
			alert("Próba pobrania niepoprawnych clientId i clientKey");
		};

		getTokens(userId).then((response) => {
			const clientId = response.data.clientId;
			const clientKey = response.data.clientKey;
			const isCarrier = response.data.roles.indexOf("CONVEYOR") > -1;
			const isReceiver = response.data.roles.indexOf("RECIPENT") > -1;

			if (userId === defaultCarrerId || userId === defaultReceiverId) {
				if (isCarrier) {
					editTokens(defaultCarrerId, defaultClientId, defaultClientKey).catch(handleError);
				} else if (isReceiver) {
					editTokens(defaultReceiverId, defaultClientId, defaultClientKey).catch(handleError);
				}
			} else {
				if (isCarrier) {
					editTokens(defaultCarrerId, clientId, clientKey).catch(handleError);
				} else if (isReceiver) {
					editTokens(defaultReceiverId, clientId, clientKey).catch(handleError);
				}
			}
		});
	};

	const handleDeleteClick = (user) => {
		setState((prevState) => {
			return {
				opened: true,
				user,
			};
		});
	};

	function handleDeleteUserModalConfirm() {
		if (state.user) {
			deleteUser(state.user.id);
		}

		setState((prevState) => {
			return {
				opened: false,
				user: null,
			};
		});
	}

	function handleDeleteUserModalCancel() {
		setState((prevState) => {
			return {
				opened: false,
				user: null,
			};
		});
	}

	function handleDeleteUserModalClose() {
		setState((prevState) => {
			return {
				opened: false,
				user: null,
			};
		});
	}

	return (
		<>
			<DeleteUserDialog open={state.opened} userToDelete={state.user} onConfirm={handleDeleteUserModalConfirm} onCancel={handleDeleteUserModalCancel} onClose={handleDeleteUserModalClose} />
			<Container>
				<Header>
					Użytkownicy
				</Header>
				<Paper>
					<table className={classes.table}>
						<tbody>
						<tr>

							<th className={classnames(classes.tableHeader)}>L.p.</th>
							<th className={classnames(classes.tableHeader)}>Login</th>
							<th className={classnames(classes.tableHeader)}>Email</th>
							<th className={classnames(classes.tableHeader)}>Role</th>

							<th className={classnames(classes.tableHeader)}>Status</th>
							<th className={classnames(classes.tableHeader)}>Rejestracja [dni]</th>
							<th className={classnames(classes.tableHeader)}>Aktywność [dni]</th>
							<th className={classnames(classes.tableHeader)}>Tokeny BDO?</th>
							<th className={classnames(classes.tableHeader)}>Akcje</th>

						</tr>
						{users.map((user: IUser, index) => (
							<tr key={user.id}>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{index + 1}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})} title={user.id}>
									{user.login}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.email || ""}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{(user.roles || []).join(", ")}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.status}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.createdAt && <Tooltip title={`Konto stworzone: ${getFormattedDate(user.createdAt)}`}><span>{getDayDifference(new Date(user.createdAt), new Date())} dni temu</span></Tooltip>}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.validUntil && <Tooltip title={`Konto aktywne do ${getFormattedDate(user.validUntil)}`}><span className={classnames({[classes.expired]: user.isExpired})}>jeszcze {getDayDifference(new Date(), new Date(user.validUntil)) } dni</span></Tooltip>}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									{user.hasBdoCredentials ? "tak" : <span className={classes.expired}>nie</span>}
								</td>
								<td className={classnames(classes.tableCell, {[classes.tableCellEven]: index % 2 === 0})}>
									<Button onClick={() => { handlePickUserClick(user.id); }} variant="contained" color="primary" startIcon={<ColorizeIcon />} style={{marginRight: "10px"}}>Pick</Button>
									<Button onClick={() => { handleEditClick(user.id); }} variant="contained" color="primary" startIcon={<EditIcon />} style={{marginRight: "10px"}}>Edytuj</Button>
									<Button onClick={() => { handleViewLogsClick(user.id); }} variant="contained" color="primary" startIcon={<LogsIcon />} style={{marginRight: "10px"}}>Logi</Button>
									<Button onClick={() => { handleDeleteClick(user); }} variant="contained" color="secondary" startIcon={<RemoveIcon />}>Usuń</Button>
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</Paper>
				<Button variant="contained" color="primary" disabled={true} startIcon={<AddIcon />} style={{marginTop: "10px"}}>Dodaj użytkownika</Button>
			</Container>
		</>
	);
}
