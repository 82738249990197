import * as React from "react";
import {useHistory} from "react-router-dom";
import {useStyles} from "./bottomMenu.styles";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import ActiveTransportsIcon from "@material-ui/icons/Assignment";
import {Locations} from "../../../../constants/locations";
import {Locations as AdminLocations} from "../../constants/locations";

export function BottomMenuComponent(props) {
	const classes = useStyles({});
	let history = useHistory();
	const selectedOption = history.location.pathname === AdminLocations.USERS || history.location.pathname === Locations.HOME
		? 0
		: -1;

	function handleChange(e, newValue) {
		switch(newValue) {
			case 0:
				history.push(AdminLocations.USERS);
				break;
		}
	}

	return (
		<BottomNavigation
			value={selectedOption}
			onChange={handleChange}
			showLabels
			classes={{root: classes.root}}
		>
			<BottomNavigationAction classes={{root: classes.buttonRoot, selected: classes.buttonSelected, label: classes.buttonLabel}} label="Użytkownicy" icon={<ActiveTransportsIcon />} />
		</BottomNavigation>
	);
}



