import {deleteUser as deleteUserAPI} from "../../api/api";
import {TYPE_DELETE_USER, TYPE_DELETE_USER_FAILURE, TYPE_DELETE_USER_SUCCESS} from "./types";
import {toast} from "react-toastify";
import {getConfiguration} from "../../../../helpers/toast";

export function deleteUser(userId) {
	return function(dispatch) {
		dispatch(deleteUserAction());

		return deleteUserAPI(userId).then((response) => {
			dispatch(deleteUserSuccessAction(response));
		}).catch((error) => {
			dispatch(deleteUserFailureAction(error));
		});
	};
}

export function deleteUserAction() {
	return {
		type: TYPE_DELETE_USER,
	};
}

export function deleteUserSuccessAction(response) {
	toast.success(`Użytkownik został ${response.data.user.login} usunięty (${response.data.logs.affected} logów)`, getConfiguration());

	return {
		type: TYPE_DELETE_USER_SUCCESS,
		response: response,
	};
}

export function deleteUserFailureAction(error) {
	return {
		type: TYPE_DELETE_USER_FAILURE,
		error,
	};
}
