import {editTokens as editTokensAPI} from "../../api/api";
import {TYPE_EDIT_TOKENS, TYPE_EDIT_TOKENS_FAILURE, TYPE_EDIT_TOKENS_SUCCESS} from "./types";
import {toast} from "react-toastify";
import {getConfiguration} from "../../../../helpers/toast";

export function editTokens(userId, clientId, clientKey) {
	return function(dispatch) {
		dispatch(editTokensAction());

		return editTokensAPI(userId, clientId, clientKey).then((response) => {
			dispatch(editTokensSuccessAction(response));
		}).catch((error) => {
			dispatch(editTokensFailureAction(error));
		});
	};
}

export function editTokensAction() {
	return {
		type: TYPE_EDIT_TOKENS,
		isLoading: true,
	};
}

export function editTokensSuccessAction(response) {
	toast.success("Tokeny zostały poprawnie zaktualizowane", getConfiguration());

	return {
		type: TYPE_EDIT_TOKENS_SUCCESS,
		isLoading: false,
		response: response,

	};
}

export function editTokensFailureAction(error) {
	return {
		type: TYPE_EDIT_TOKENS_FAILURE,
		isLoading: false,
		error,
	};
}
