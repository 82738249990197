import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
	return {
		root: {

		},
		appBarWrapper: {
			flexGrow: 1,
		},
		appBar: {

		},
		toolbar: {
			minHeight: "48px",
		},
		menuButton: {
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)",
			right: 0,
		},
		reloadButton: {
			position: "absolute",
			top: "50%",
			transform: "translateY(-50%)",
			left: 0,
		},
		title: {
			flexGrow: 1,
			fontWeight: 700,
			fontStyle: "normal",
			color: "white",
			fontSize: "14px",
			lineHeight: "1.1",
			letterSpacing: 0,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",

			position: "absolute",
			top: "50%",
			transform: "translate(-50%, -50%)",
			left: "50%",
		},
		logo: {
			background: "url(logo.jpg) no-repeat 0 0",
			width: "30px",
			height: "30px",
			display: "inline-block",
		},
		userInfo: {
			fontSize: "11px",
			padding: "0 15px 10px 10px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			outline: "none",
		},
		userInfoIcon: {
			marginRight: "5px",
		}
	};
});

