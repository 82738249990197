import {connect} from "react-redux";
import {TopMenuComponent} from "./topMenu.component";
import {logoutAction} from "../../../../store/actions";
import {isLoggedIn, getAccountData} from "../../../../store/selectors/app.selectors";

const mapStateToProps = (state) => {
	return {
		isLoggedIn: isLoggedIn(state),
		accountData: getAccountData(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => { dispatch(logoutAction()); }
	};
};

export const TopMenu = connect(
	mapStateToProps,
	mapDispatchToProps
)(TopMenuComponent);


