import {connect} from "react-redux";
import {EditTokensComponent} from "./editTokens.component";
import {getFormData} from "../../../../store/selectors/app.selectors";
import {getError} from "../../../../store/selectors/app.selectors";
import {editTokens} from "../../store/actions/editTokens.actions";

const mapStateToProps = (state) => {
	return {
		exchangedData: getFormData(state),
		error: getError(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editTokens: (userId, clientId, clientKey) => { dispatch(editTokens(userId, clientId, clientKey)); },
	};
};

export const EditTokensRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(EditTokensComponent);
