import {fetchUsers as fetchUsersAPI} from "../../api/api";
import {TYPE_FETCH_USERS, TYPE_FETCH_USERS_FAILURE, TYPE_FETCH_USERS_SUCCESS} from "./types";

export function fetchUsers(apiType) {
	return function(dispatch) {
		dispatch(fetchUsersAction());

		return fetchUsersAPI(apiType).then((response) => {
			dispatch(fetchUsersSuccessAction(response));
		}).catch((error) => {
			dispatch(fetchUsersFailureAction(error));
		});
	};
}

export function fetchUsersAction() {
	return {
		type: TYPE_FETCH_USERS,
		isLoading: true,
	};
}

export function fetchUsersSuccessAction(response) {
	return {
		type: TYPE_FETCH_USERS_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function fetchUsersFailureAction(error) {
	return {
		type: TYPE_FETCH_USERS_FAILURE,
		isLoading: false,
		error,
	};
}
