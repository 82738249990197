import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	table: {
		minWidth: 650,
		textAlign: "center",
	},
	expired: {
		color: "red",
	},
	tableHeader: {
		backgroundColor: "#1a6c40",
		color: "white !important",
		padding: "5px"
	},
	tableCell: {
		backgroundColor: "white"
	},
	tableRow: {
		cursor: "pointer",
	},
	tableRowSelected: {
		"& td": {
			backgroundColor: "orange",
		}
	},
	tableCellEven: {
		backgroundColor: "#ebebeb",
	},
	logDetailsTable: {
		marginLeft: "100px",
		"& td": {
			border: "1px solid black",
			padding: "3px"
		}
	},
	colLP: {
		width: "15px",
		paddingRight: "16px",
	},
	tablesWrapper: {
		display : "flex"
	}
}));

