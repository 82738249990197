import {fetchUserLogs as fetchUserLogsAPI, IFetchUserLogsParams} from "../../api/api";
import {TYPE_FETCH_USER_LOGS, TYPE_FETCH_USER_LOGS_FAILURE, TYPE_FETCH_USER_LOGS_SUCCESS} from "./types";

export function fetchUserLogs(queryData: IFetchUserLogsParams) {
	return function(dispatch) {
		dispatch(fetchUserLogsAction());

		return fetchUserLogsAPI(queryData).then((response) => {
			dispatch(fetchUserLogsSuccessAction(response));
		}).catch((error) => {
			dispatch(fetchUserLogsFailureAction(error));
		});
	};
}

export function fetchUserLogsAction() {
	return {
		type: TYPE_FETCH_USER_LOGS,
		isLoading: true,
	};
}

export function fetchUserLogsSuccessAction(response) {
	return {
		type: TYPE_FETCH_USER_LOGS_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function fetchUserLogsFailureAction(error) {
	return {
		type: TYPE_FETCH_USER_LOGS_FAILURE,
		isLoading: false,
		error,
	};
}
