import React from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {globalStyles} from "../../../../css/global.styles";
import {ISetPasswordFormValues} from "../../interfaces/forms/setPassword";
import {Textbox} from "../../../../components/textbox/textbox";
import {Header} from "../../../../components/header/header.component";
import {Locations as AdminLocations} from "../../constants/locations";
import {Redirect, useHistory} from "react-router-dom";
import {Container} from "../../../../components/container/container.component";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {ErrorMessage} from "../../../../components/errorMessage";
import {containsSpaces, isValidPassword} from "../../../../helpers/validation";

export function SetPasswordComponent(props) {
	const {exchangedData, error, setPassword} = props;
	const globalClasses = globalStyles({});
	const userId = lodashGet(exchangedData, "userId");

	let history = useHistory();

	const initialValues: ISetPasswordFormValues = {
		password: "",
		confirmedPassword: "",
	};

	const handleGoBack = () => {
		history.push(AdminLocations.EDIT_USER);
	};

	const handleValidation = (values: ISetPasswordFormValues) => {
		const errors: any = {};

		if (!values.password) {
			errors.password = "To pole jest wymagane";
		}

		if (!isValidPassword(values.password)) {
			errors.password = "Hasło powinno zawierać od 5 do 20 znaków (cyfry od 0-9 lub litery a-z)";
		}

		if (containsSpaces(values.password)) {
			errors.password = "Hasło nie może zawierac spacji";
		}

		if (!values.confirmedPassword) {
			errors.confirmedPassword = "To pole jest wymagane";
		}

		if (containsSpaces(values.confirmedPassword)) {
			errors.confirmedPassword = "Hasło nie może zawierac spacji";
		}

		if (values.password !== values.confirmedPassword) {
			errors.confirmedPassword = "Hasła muszą być identyczne";
		}

		return errors;
	};

	const onSubmit = async (values: ISetPasswordFormValues) => {
		setPassword(userId, values.password, values.confirmedPassword);
	};

	if (!Object.keys(lodashGet(exchangedData, "formValues", {})).length) {
		return (
			<Redirect
				to={{
					pathname: AdminLocations.USERS,
				}}
			/>
		);
	} else {
		return (
			<Container>
				<Header>
					Edycja hasła dla {lodashGet(exchangedData, "formValues.login")}
				</Header>
				<Form
					onSubmit={onSubmit}
					validate={handleValidation}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => {
						return (
							<form onSubmit={handleSubmit} noValidate={true}>
								<FormFieldWrapper>
									<Textbox
										name="password"
										label="Nowe hasło"
										className={globalClasses.inputWidth}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<Textbox
										name="confirmedPassword"
										label="Powtórz nowe hasło"
										className={globalClasses.inputWidth}
										serverErrors={error}
									/>
								</FormFieldWrapper>

								<FormFieldWrapper alignContent="right">
									<Button onClick={handleGoBack} className={globalClasses.centerButton} variant="contained" color="secondary">
										Cofnij
									</Button>
									<Button className={globalClasses.centerButton} variant="contained" color="primary" type="submit" disabled={submitting}>
										Zapisz
									</Button>
								</FormFieldWrapper>

								<ErrorMessage errors={error} type="general" />
							</form>
						);
					}}
				/>
			</Container>
		);
	}
}



