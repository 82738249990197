import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import {LoginRoute} from "../../../../routes/login";
import {SelectPlaceOfBusinessRoute} from "../../../../routes/selectPlaceOfBusiness";
import {AccountExpiredRoute} from "../../../../routes/accountExpired";
import {AccountBlockedRoute} from "../../../../routes/accountBlocked";
import {RegisterRoute} from "../../../../routes/register";
import {PrivateRoute} from "../../../../routes/privateRoute";
import {SettingsRoute} from "../../../../routes/settings";
import {TopMenu} from "../../widgets/topMenu";
import {BottomMenu} from "../../widgets/bottomMenu";
import {RouterHelper} from "../../../../containers/routerHelper";
import {Loader} from "../../../../components/loader";

import {createMuiTheme, responsiveFontSizes} from "@material-ui/core/styles";
// import errorColor from '@material-ui/core/colors/red';
// import secondaryColor from '@material-ui/core/colors/grey';
// import primaryColor from '@material-ui/core/colors/lightGreen';
import {ThemeProvider} from "@material-ui/core";
import ScrollToTop from "../../../../components/scrollToTop/scrollToTop.component";
import {useStyles} from "./app.styles";
import {Locations} from "../../../../constants/locations";
import {Locations as AdminLocations} from "../../constants/locations";
import { ToastContainer } from "react-toastify";
import {AccountTypes} from "../../../../constants/accountTypes";
import {RoleTypes} from "../../../../constants/roleTypes";
import {UsersRoute} from "../../routes/users";
import {EditUserRoute} from "../../routes/editUser";
import {ViewUserLogsRoute} from "../../routes/viewUserLogs";
import {EditTokensRoute} from "../../routes/editTokens";
import {SetPasswordRoute} from "../../routes/setPassword";

var lightGreen = {
	50: '#1a6c40',
	100: '#1a6c40',
	200: '#1a6c40',
	300: '#1a6c40',
	400: '#1a6c40',
	500: '#1a6c40',
	600: '#1a6c40',
	700: '#1a6c40',
	800: '#1a6c40',
	900: '#1a6c40',
	A100: '#1a6c40',
	A200: '#1a6c40',
	A400: '#1a6c40',
	A700: '#1a6c40',
	contrastText: "#ffffff",
};

var errorPalette = {
	50: '#ff0000',
	100: '#ff0000',
	200: '#ff0000',
	300: '#ff0000',
	400: '#ff0000',
	500: '#ff0000',
	600: '#ff0000',
	700: '#ff0000',
	800: '#ff0000',
	900: '#ff0000',
	A100: '#ff0000',
	A200: '#ff0000',
	A400: '#ff0000',
	A700: '#ff0000',
	contrastText: "#ffffff",
};

let theme = createMuiTheme({
	palette: {
		primary: lightGreen,
		secondary: errorPalette,
	},
	typography: {
		fontSize: 14,
		fontFamily: "Lato",
	},
});

theme = responsiveFontSizes(theme);

export function AppComponent(props) {
	const classes = useStyles({});
	const {isLoggedIn, isLoading} = props;

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<ScrollToTop />
				<RouterHelper />
				<TopMenu />
				<ToastContainer />
				<div className={classes.container}>
					<Switch>
						<Route path={Locations.REGISTER}>
							<RegisterRoute accountType={AccountTypes.CARRIER} />
						</Route>
						<Route path={Locations.LOGIN}>
							<LoginRoute roleType={(RoleTypes.ADMIN)} />
						</Route>
						<Route path={Locations.ACCOUNT_BLOCKED}>
							<AccountBlockedRoute />
						</Route>
						<Route path={Locations.SELECT_PLACE_OF_BUSINESS}>
							<SelectPlaceOfBusinessRoute roleType={RoleTypes.ADMIN} />
						</Route>
						<PrivateRoute path={Locations.ACCOUNT_EXPIRED}>
							<AccountExpiredRoute />
						</PrivateRoute>
						<PrivateRoute path={AdminLocations.EDIT_TOKENS}>
							<EditTokensRoute />
						</PrivateRoute>
						<PrivateRoute path={AdminLocations.SET_PASSWORD}>
							<SetPasswordRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.SETTINGS}>
							<SettingsRoute />
						</PrivateRoute>
						<PrivateRoute path={AdminLocations.EDIT_USER}>
							<EditUserRoute />
						</PrivateRoute>
						<PrivateRoute path={AdminLocations.USERS}>
							<UsersRoute />
						</PrivateRoute>
						<PrivateRoute path={AdminLocations.VIEW_USER_LOGS}>
							<ViewUserLogsRoute />
						</PrivateRoute>
						<PrivateRoute path={Locations.HOME}>
							<UsersRoute />
						</PrivateRoute>
					</Switch>
				</div>
				{isLoggedIn && <BottomMenu />}
			</Router>
			{<Loader show={isLoading} />}
		</ThemeProvider>
	);
}
