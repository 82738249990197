import {connect} from "react-redux";
import {EditUserComponent} from "./editUser.component";
import {getFormData} from "../../../../store/selectors/app.selectors";
import {getError} from "../../../../store/selectors/app.selectors";
import {editUser} from "../../store/actions/editUser.actions";
import {updateFormDataAction} from "../../../../store/actions";

const mapStateToProps = (state) => {
	return {
		exchangedData: getFormData(state),
		error: getError(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		editUser: (userId, formValues) => { dispatch(editUser(userId, formValues)); },
		updateFormData: (exchangedData) => { dispatch(updateFormDataAction(exchangedData)); },
	};
};

export const EditUserRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(EditUserComponent);
