import {editUser as editUserAPI} from "../../api/api";
import {TYPE_EDIT_USER, TYPE_EDIT_USER_FAILURE, TYPE_EDIT_USER_SUCCESS} from "./types";
import {toast} from "react-toastify";
import {getConfiguration} from "../../../../helpers/toast";

export function editUser(userId, formValues) {
	return function(dispatch) {
		dispatch(editUserAction());

		return editUserAPI(userId, formValues).then((response) => {
			dispatch(editUserSuccessAction(response));
		}).catch((error) => {
			dispatch(editUserFailureAction(error));
		});
	};
}

export function editUserAction() {
	return {
		type: TYPE_EDIT_USER,
		isLoading: true,
	};
}

export function editUserSuccessAction(response) {
	toast.success("Dane zostały poprawnie zaktualizowane", getConfiguration());

	return {
		type: TYPE_EDIT_USER_SUCCESS,
		isLoading: false,
		response: response,
	};
}

export function editUserFailureAction(error) {
	return {
		type: TYPE_EDIT_USER_FAILURE,
		isLoading: false,
		error,
	};
}
