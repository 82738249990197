import {connect} from "react-redux";
import {SetPasswordComponent} from "./setPassword.component";
import {getFormData} from "../../../../store/selectors/app.selectors";
import {getError} from "../../../../store/selectors/app.selectors";
import {setPassword} from "../../store/actions/setPassword.actions";

const mapStateToProps = (state) => {
	return {
		exchangedData: getFormData(state),
		error: getError(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setPassword: (userId, password, confirmedPassword) => { dispatch(setPassword(userId, password, confirmedPassword)); },
	};
};

export const SetPasswordRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)(SetPasswordComponent);
