import React from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {globalStyles} from "../../../../css/global.styles";
import {IEditUserFormValues} from "../../interfaces/forms/editUser";
import {Textbox} from "../../../../components/textbox/textbox";
import {MultiSelect} from "../../../../components/multiSelect/multiSelect";
import {Header} from "../../../../components/header/header.component";
import {Locations as AdminLocations} from "../../constants/locations";
import {Redirect, useHistory} from "react-router-dom";
import {Container} from "../../../../components/container/container.component";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {ErrorMessage} from "../../../../components/errorMessage";
import {MenuItem} from "@material-ui/core";
import {Select} from "../../../../components/select/select";
import {RoleTypesList} from "../../../../constants/roleTypes";
import {UserStatusList} from "../../../../constants/userStatus";
import {isValidLogin, isValidEmail, isValidDateTime, containsSpaces} from "../../../../helpers/validation";

export function EditUserComponent(props) {
	const {exchangedData, error, editUser, updateFormData} = props;
	const globalClasses = globalStyles({});
	const userId = lodashGet(exchangedData, "userId");

	let history = useHistory();

	const initialValues: IEditUserFormValues = {
		id: lodashGet(exchangedData, "formValues.id") || "",
		createdAt: lodashGet(exchangedData, "formValues.createdAt") || "",
		updatedAt: lodashGet(exchangedData, "formValues.updatedAt") || "",
		login: lodashGet(exchangedData, "formValues.login") || "",
		email: lodashGet(exchangedData, "formValues.email") || "",
		roles: lodashGet(exchangedData, "formValues.roles") || [],
		regulationsConfirmed: lodashGet(exchangedData, "formValues.regulationsConfirmed") || false,
		validUntil: lodashGet(exchangedData, "formValues.validUntil") || "",
		status: lodashGet(exchangedData, "formValues.status") || "",
		hasBdoCredentials: lodashGet(exchangedData, "formValues.hasBdoCredentials") || false,
		isExpired: lodashGet(exchangedData, "formValues.isExpired") || false,
	};

	const handleGoBack = () => {
		history.push(AdminLocations.USERS);
	};

	const handleEditTokens = () => {
		updateFormData({
			from: AdminLocations.EDIT_USER,
			userId: userId,
			formValues: exchangedData.formValues,
		});
		history.push(AdminLocations.EDIT_TOKENS);
	};

	const handleSetPassword = () => {
		updateFormData({
			from: AdminLocations.EDIT_USER,
			userId: userId,
			formValues: exchangedData.formValues,
		});
		history.push(AdminLocations.SET_PASSWORD);
	};

	const handleValidation = (values: IEditUserFormValues) => {
		const errors: any = {};

		if (!values.login) {
			errors.login = "To pole jest wymagane";
		}

		if (!isValidLogin(values.login)) {
			errors.login = "Nazwa użytkownika powinna składać się z 5 do 50 znaków";
		}

		if (containsSpaces(values.login)) {
			errors.login = "Nazwa użytkownika nie może zawierac spacji";
		}

		if (!values.email) {
			errors.email = "To pole jest wymagane";
		}

		if (!isValidEmail(values.email)) {
			errors.email = "Niepoprawny adres e-mail";
		}

		if (containsSpaces(values.email)) {
			errors.email = "E-mail nie może zawierac spacji";
		}

		if (!values.roles.length) {
			errors.roles = "Użytkownik musi posiada conajmmiej jedną rolę";
		}

		if (UserStatusList.indexOf(values.status) === -1) {
			errors.status = `Niepoprawny status użytkownika (${UserStatusList.join(", ")})`;
		}

		if (!isValidDateTime(values.validUntil)) {
			errors.validUntil = "Niepoprawny format daty";
		}

		//Specjalnie nie waliduje clientId i clientSecret

		return errors;
	};

	const onSubmit = async (values: IEditUserFormValues) => {
		editUser(userId, values);
	};

	if (!Object.keys(lodashGet(exchangedData, "formValues", {})).length) {
		return (
			<Redirect
				to={{
					pathname: AdminLocations.USERS,
				}}
			/>
		);
	} else {
		return (
			<Container>
				<Header>
					Edycja użytkownika {lodashGet(exchangedData, "formValues.login")}
				</Header>
				<FormFieldWrapper alignContent="right">
					<Button onClick={handleEditTokens} className={globalClasses.centerButton} variant="contained" color="primary">
						Edytuj tokeny
					</Button>
					<Button onClick={handleSetPassword} className={globalClasses.centerButton} variant="contained" color="primary">
						Edytuj hasło
					</Button>
				</FormFieldWrapper>
				<Form
					onSubmit={onSubmit}
					validate={handleValidation}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values }) => {
						return (
							<form onSubmit={handleSubmit} noValidate={true}>
								<FormFieldWrapper>
									<Textbox
										required={true}
										disabled={true}
										name="id"
										label="ID"
										className={globalClasses.inputWidth}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<Textbox
										required={true}
										name="login"
										label="Login"
										className={globalClasses.inputWidth}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<Textbox
										required={true}
										name="email"
										label="Email"
										className={globalClasses.inputWidth}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<MultiSelect
										required={true}
										name="roles"
										label="Rola"
										serverErrors={error}
									>
										{RoleTypesList.map((role, index) => {
											return (<MenuItem key={index} value={role}>{role}</MenuItem>);
										})}
									</MultiSelect>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<Textbox
										required={true}
										name="validUntil"
										label="Konto ważne do"
										className={globalClasses.inputWidth}
										serverErrors={error}
									/>
								</FormFieldWrapper>
								<FormFieldWrapper>
									<Select
										required={true}
										name="status"
										label="Status"
										serverErrors={error}
									>
										{UserStatusList.map((statusType, index) => {
											return (<MenuItem key={index} value={statusType}>{statusType}</MenuItem>);
										})}
									</Select>
								</FormFieldWrapper>

								<FormFieldWrapper alignContent="right">
									<Button onClick={handleGoBack} className={globalClasses.centerButton} variant="contained" color="secondary">
										Cofnij
									</Button>
									<Button className={globalClasses.centerButton} variant="contained" color="primary" type="submit" disabled={submitting}>
										Zapisz
									</Button>
								</FormFieldWrapper>

								<ErrorMessage errors={error} type="general" />
							</form>
						);
					}}
				/>
			</Container>
		);
	}
}



