import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	table: {
		minWidth: 650,
		textAlign: "center",
	},
	expired: {
		color: "red",
	},
	tableHeader: {
		backgroundColor: "#1a6c40",
		color: "white !important",
		padding: "5px"
	},
	tableCell: {
		backgroundColor: "white"
	},
	tableCellEven: {
		backgroundColor: "#ebebeb"
	},
}));

