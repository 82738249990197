import {
	TYPE_CHANGE_API_TYPE,
	TYPE_CHANGE_PASSWORD,
	TYPE_CHANGE_PASSWORD_FAILURE,
	TYPE_CHANGE_PASSWORD_SUCCESS,
	TYPE_CLEAR_LOCATION_TO_CHANGE_TO,
	TYPE_CLEAR_REFRESH_CURRENT_PAGE,
	TYPE_GET_USER,
	TYPE_GET_USER_FAILURE,
	TYPE_GET_USER_SUCCESS,
	TYPE_LOGIN,
	TYPE_LOGIN_FAILURE,
	TYPE_LOGIN_SUCCESS,
	TYPE_LOGOUT,
	TYPE_REGISTER,
	TYPE_REGISTER_FAILURE,
	TYPE_REGISTER_SUCCESS,
	TYPE_UPDATE_BDO_CREDENTIALS,
	TYPE_UPDATE_BDO_CREDENTIALS_FAILURE,
	TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS,
	TYPE_UPDATE_FORM_DATA,
} from "../../../../store/actions/types";

import {
	TYPE_EDIT_USER,
	TYPE_EDIT_USER_FAILURE,
	TYPE_EDIT_USER_SUCCESS,
	TYPE_FETCH_USERS,
	TYPE_FETCH_USERS_FAILURE,
	TYPE_FETCH_USERS_SUCCESS,
	TYPE_FETCH_USER_LOGS,
	TYPE_FETCH_USER_LOGS_FAILURE,
	TYPE_FETCH_USER_LOGS_SUCCESS,
	TYPE_DELETE_USER,
	TYPE_DELETE_USER_FAILURE,
	TYPE_DELETE_USER_SUCCESS,
	TYPE_EDIT_TOKENS,
	TYPE_EDIT_TOKENS_FAILURE,
	TYPE_EDIT_TOKENS_SUCCESS,
	TYPE_SET_PASSWORD,
	TYPE_SET_PASSWORD_FAILURE,
	TYPE_SET_PASSWORD_SUCCESS,
} from "../../store/actions/types";

import {IUser} from "../../../../interfaces/user";
import {Locations as AdminLocations} from "../../constants/locations";
import {ApiTypes} from "../../../../constants/apiTypes";

import {IAccessToken} from "../../../../interfaces/accessToken";
import {IAccountData} from "../../../../interfaces/accountData";
import {getClearLocationToChangeToReducer} from "../../../../store/reducers/clearLocationToChangeTo";
import {getClearRefreshCurrentPageReducer} from "../../../../store/reducers/clearRefreshCurrentPage";
import {getUpdateFormDataReducer} from "../../../../store/reducers/updateFormData";
import {getDefaultActionReducer} from "../../../../store/reducers/defaultAction";
import {getLoginSuccessReducer} from "../../../../store/reducers/loginSuccess";
import {getLoginFailureReducer} from "../../../../store/reducers/loginFailure";
import {getDefaultFailureActionReducer} from "../../../../store/reducers/defaultFailureAction";
import {getUpdateBDOCredentialsSuccessReducer} from "../../../../store/reducers/updateBDOCredentialsSuccess";
import {getLogoutReducer} from "../../../../store/reducers/logout";
import {getGetUserReducer} from "../../../../store/reducers/getUser";
import {getGetUserSuccessReducer} from "../../../../store/reducers/getUserSuccess";
import {getGetUserFailureReducer} from "../../../../store/reducers/getUserFailure";
import {getRegisterSuccessReducer} from "../../../../store/reducers/registerSuccess";
import {getChangePasswordSuccessReducer} from "../../../../store/reducers/changePasswordSuccess";
import {getChangeApiTypeReducer} from "../../../../store/reducers/changeApiType";
import {getDeleteUserSuccessReducer} from "./deleteUser";
import {ILogs} from "../../interfaces/logs";

export interface IAppState {
	isDisconnected: boolean;
	isLoggedIn: boolean;
	isLoading: boolean;
	isBlocked: boolean;
	isExpired: boolean;
	hasBDOTokens: boolean | null;
	accessToken: IAccessToken | null,
	accountData: IAccountData | null,
	isFetchingAccountData: boolean,
	locationToChangeTo: string | null;
	refreshCurrentPageStatus: boolean | null;
	error: any | null; // object
	exchangedData: any; // object
	apiType: ApiTypes.KPO | ApiTypes.KPOK,

	users: IUser[];
	logs: ILogs;
}

const defaultState: IAppState = {
	isDisconnected: false,
	isLoggedIn: false,
	isLoading: false,
	isBlocked: false,
	isExpired: false,
	hasBDOTokens: null,
	accessToken: null,
	accountData: null,
	isFetchingAccountData: false,
	locationToChangeTo: null, //pomaga obejsc problem z polaczeniem Redux i ReactRouter (ma tylko triggerowac zmiane urla, nie wskazuje na poprawny aktualny adres)
	refreshCurrentPageStatus: null,
	error: null,
	exchangedData: {},
	apiType: ApiTypes.KPO,

	users: null,
	logs: null,
};

export function appReducers(state = defaultState, action) {
	switch (action.type) {
		case TYPE_CLEAR_LOCATION_TO_CHANGE_TO:
			return getClearLocationToChangeToReducer(state, action);
		case TYPE_CLEAR_REFRESH_CURRENT_PAGE:
			return getClearRefreshCurrentPageReducer(state, action);
		case TYPE_UPDATE_FORM_DATA:
			return getUpdateFormDataReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS:
			return getDefaultActionReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS_SUCCESS:
			return getUpdateBDOCredentialsSuccessReducer(state, action);
		case TYPE_UPDATE_BDO_CREDENTIALS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_LOGIN:
			return getDefaultActionReducer(state, action);
		case TYPE_LOGIN_SUCCESS:
			return getLoginSuccessReducer(state, action);
		case TYPE_LOGIN_FAILURE:
			return getLoginFailureReducer(state, action);
		case TYPE_GET_USER:
			return getGetUserReducer(state, action);
		case TYPE_GET_USER_SUCCESS:
			return getGetUserSuccessReducer(state, action);
		case TYPE_GET_USER_FAILURE:
			return getGetUserFailureReducer(state, action);
		case TYPE_REGISTER:
			return getDefaultActionReducer(state, action);
		case TYPE_REGISTER_SUCCESS:
			return getRegisterSuccessReducer(state, action);
		case TYPE_REGISTER_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_LOGOUT:
			return getLogoutReducer(state, action);
		case TYPE_CHANGE_PASSWORD:
			return getDefaultActionReducer(state, action);
		case TYPE_CHANGE_PASSWORD_SUCCESS:
			return getChangePasswordSuccessReducer(state, action);
		case TYPE_CHANGE_PASSWORD_FAILURE:
			return getDefaultFailureActionReducer(state, action);

		case TYPE_FETCH_USERS:
			return getDefaultActionReducer(state, action);
		case TYPE_FETCH_USERS_SUCCESS:
			return {
				...state,
				isLoading: action.isLoading,
				error: null,
				users: action.response.data,
			};
		case TYPE_FETCH_USERS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_EDIT_USER:
			return getDefaultActionReducer(state, action);
		case TYPE_EDIT_USER_SUCCESS:
			return {
				...state,
				isLoading: action.isLoading,
				error: null,
				locationToChangeTo: AdminLocations.USERS,
			};
		case TYPE_EDIT_USER_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_CHANGE_API_TYPE:
			return getChangeApiTypeReducer(state, action);
		case TYPE_FETCH_USER_LOGS:
			return getDefaultActionReducer(state, action);
		case TYPE_FETCH_USER_LOGS_SUCCESS:
			return {
				...state,
				isLoading: action.isLoading,
				error: null,
				logs: action.response.data,
			};
		case TYPE_FETCH_USER_LOGS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_DELETE_USER:
			return getDefaultActionReducer(state, action);
		case TYPE_DELETE_USER_SUCCESS:
			return getDeleteUserSuccessReducer(state, action);
		case TYPE_DELETE_USER_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_EDIT_TOKENS:
			return getDefaultActionReducer(state, action);
		case TYPE_EDIT_TOKENS_SUCCESS:
			return {
				...state,
				isLoading: action.isLoading,
				error: null,
				locationToChangeTo: AdminLocations.EDIT_USER,
			};
		case TYPE_EDIT_TOKENS_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		case TYPE_SET_PASSWORD:
			return getDefaultActionReducer(state, action);
		case TYPE_SET_PASSWORD_SUCCESS:
			return {
				...state,
				isLoading: action.isLoading,
				error: null,
				locationToChangeTo: AdminLocations.EDIT_USER,
			};
		case TYPE_SET_PASSWORD_FAILURE:
			return getDefaultFailureActionReducer(state, action);
		default:
			return state;
	}
}
