import {setPassword as setPasswordAPI} from "../../api/api";
import {TYPE_SET_PASSWORD, TYPE_SET_PASSWORD_FAILURE, TYPE_SET_PASSWORD_SUCCESS} from "./types";
import {toast} from "react-toastify";
import {getConfiguration} from "../../../../helpers/toast";

export function setPassword(userId, clientId, clientKey) {
	return function(dispatch) {
		dispatch(setPasswordAction());

		return setPasswordAPI(userId, clientId, clientKey).then((response) => {
			dispatch(setPasswordSuccessAction(response));
		}).catch((error) => {
			dispatch(setPasswordFailureAction(error));
		});
	};
}

export function setPasswordAction() {
	return {
		type: TYPE_SET_PASSWORD,
		isLoading: true,
	};
}

export function setPasswordSuccessAction(response) {
	toast.success("Hasło zostało poprawnie zaktualizowane", getConfiguration());

	return {
		type: TYPE_SET_PASSWORD_SUCCESS,
		isLoading: false,
		response: response,

	};
}

export function setPasswordFailureAction(error) {
	return {
		type: TYPE_SET_PASSWORD_FAILURE,
		isLoading: false,
		error,
	};
}
