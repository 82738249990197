import * as React from "react";
import {Dialog} from "../../../../../components/dialog";

export function DeleteUserDialog(props) {
	const {open = false, onCancel, onConfirm, onClose, userToDelete} = props;

	if (!userToDelete) {
		return null;
	}

	return (
		<Dialog
			open={open}
			onConfirm={onConfirm}
			onCancel={onCancel}
			onClose={onClose}
			title={"Usunięcie użytkownika"}
			description={`Czy jesteś pewien że chcesz usunąć użytkownika ${userToDelete.login}?`}
			confirmButtonLabel={"Tak"}
			cancelButtonLabel={"Nie"}
		/>
	);
}

